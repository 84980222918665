import React from 'react';
import Contact from '../Component/Contact/Contact';

const Contactpage = () => {
    return (
        <>
         <Contact/>   
        </>
    );
}

export default Contactpage;
