import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import Blogsection from './Blogsection';

const Blogs = () => {

    const blogPage_Ref = useRef();

    return (
        <div ref={blogPage_Ref} className="blog_page_container">
            <Blogsection />
        </div>
    );
}

export default Blogs;
