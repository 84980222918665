import Equipment from "../../Assets/Images/Infrastructure/Equipment.jpg";
import Privateroom from "../../Assets/Images/Infrastructure/Privateroom.jpeg";
import Publicroom from "../../Assets/Images/Infrastructure/Publicroom.jpeg"
import Publicroom2 from "../../Assets/Images/Infrastructure/Publicroom2.jpeg"


export const Model = [
    {
        name: "Machine: BPL E-Flow 6c",
        description: "The BPL E-Flow 6c is a versatile machine whose primary application is in endoscopic procedures, specifically for urological interventions such as cystoscopy, ureteroscopy, and transurethral resection of the prostate (TURP).",
        img: Equipment
    },
    {
        name: "Personal Wards",
        description: "Private AC rooms with enclosed western toilet, seating couch for guests & family members, and TV for entertainment.",
        img: Privateroom
    },
    {
        name: "Modern General Ward",
        description: "Our general ward comprises of 8 beds with separators to ensure privacy for our patients. It is regularly cleaned and maintained for the highest standards of hygiene. ",
        img: Publicroom
    },
]