import React, { useState } from 'react';
import Asset_1 from "../../Assets/Images/Asset 1.png"
import Asset_2 from "../../Assets/Images/Asset 2.png"
import Asset_3 from "../../Assets/Images/Asset 3.png"
import Asset_4 from "../../Assets/Images/Asset 4.png"

const Achivement = () => {
    const [badges, setbadges] = useState([
        {
            heading: "30+",
            sub: "years of experience",
            image: Asset_4,
        },
        {
            heading: "20000+",
            sub: "satisfied customers",
            image: Asset_3,
        }, {
            heading: "10000+",
            sub: "urological surgeries",
            image: Asset_2,
        }, {
            heading: "15000+",
            sub: "outpatient surgeries",
            image: Asset_1,
        },
    ]);
    return (
        <div className='section achivement_section flex items-center justify-center'>
            <div className="achivement_container flex items-center justify-center flex-wrap">
                {badges.map((val, i) => (
                    <div key={`achivement${i}`} className="achivement flex items-center w-2/5 m-8 justify-center">
                        <div className="image">
                            <img src={val.image} alt="" />
                        </div>
                        <div className="text p-6">
                            <h1 className='text-5xl font-bold'>{val.heading}</h1>
                            <p className='text-2xl font-bold'>{val.sub}</p>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default Achivement;
