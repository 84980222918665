import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';


const Testimonial = () => {


    const [reviews, setreviews] = useState([
        {
            name: "Mrs. Apurvaa Rajvi Mohan - Pune.",
            review: "My relative went for a check up and found the treatment extremely helpful and guided. He was very patient and understanding of the situation and explained everything thoroughly in detail. Highly recommend him, one of the best experiences I’ve had.",
        },
        {
            name: "Mr. Anupam Champati - Pune.",
            review: `I am very happy with the quality of treatment and kindness of the docter. He explained me the about the treatment in detailed and whole process was mostly painless. It was first time in my life i have been admitted to hospital and he made me very comfortable. I would recommend Dr Rajeev sir for any urologist treatment.`,
        },
        {
            name: "Mr. Vaibhav Kale - Pune.",
            review: "The most trusted place. Operation done and discharge within 24 hrs.staff. nurse. Sister brother very cooperative, and down to earth",
        },
        {
            name: "Pankti Tanna",
            review: "My mother received treatment for a complex and huge kidney stone from Dr Rajeev and Dr Veena Chaudhary. Dr Rajeev is excellent in his skills and knowledge for taking appropriate decisions and provides best guidance and is excellent in his surgical skills. He has excellent methods to solve the problems. I sincerely thank the entire team at Rahee healthcare for their continues efforts and help. Highly recommend Dr Rajeev and Dr Veena Chaudhary.",
        },
        {
            name: "Mr. Suresh choudhary",
            review: "We have done 4 surgery at Rahee health care and everything so  clean and perfect. They have good team to take care for patients and also Dr Rajeev choudhari and Dr Veena Choudhari are understanding and guide in very good way. We have very good experience at Rahee health care and I prefer you to visit and have look how they helps you in your treatment.",
        },
        {
            name: "Dhanashri Lokhande",
            review: "Great doctor. Explained everything very well to me, all my doubts were cleared and was solved quickly and efficiently. Reliable and affordable, Highly recommend him..",
        },
    ]);

    const responsive = {
        0: {
            items: 1,
        },
        992: {
            items: 2,
        },
        1500: {
            items: 3,
        },
    }

    return (
        <div className='section testimonial_container relative flex items-center justify-evenly flex-col'>
            <div className="heading_container">
                <h1 className="services_heading">
                    Testimonial
                </h1>
                <p>
                    Overview of all the treatments/surgeries the Hospital offers
                </p>
            </div>

            <div className="testimonal_card_container flex items-center justify-between w-4/5">
                <OwlCarousel className='owl-theme' autoPlay responsive={responsive} margin={20}>
                    {reviews.map((val, i) => (
                        <div key={`service${i}`} className="testimonial_card p-7">
                            <p className="name mb-10">
                                {val.name}
                            </p>
                            <p className="review">
                                {val.review}
                            </p>
                        </div>
                    ))}
                </OwlCarousel>
            </div>

        </div>
    );
}

export default Testimonial;
