import Lenis from "@studio-freight/lenis";
import { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import Footer from "./Common/Footer";
import Navbar from './Common/Navbar';
import Blogdetail from "./Component/Blogs/Blogdetail";
import About from "./Component/Home/About";
import Achivement from "./Component/Home/Achivement";
import Banner from "./Component/Home/Banner";
import Services from "./Component/Home/Services";
import Testimonial from "./Component/Home/Testimonial";
import Subservice from "./Component/Subservices.js/Subservice";
import Aboutpage from "./Pages/Aboutpage";
import Blogspage from "./Pages/Blogspage";
import Contactpage from "./Pages/Contactpage";
import Homepage from "./Pages/Homepage";
import Servicespage from "./Pages/Servicespage";
import "./Styles/Styles.css"
import Infrasturecture from "./Component/Infrastructure/Infrasturecture";

function App() {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    
    if (window.innerWidth <= 992) {
      document.querySelector(".menus").classList.remove("menu--open")
    }
  }, [location.pathname]);

  useEffect(() => {
    let lenis;

    const initSmoothScrolling = () => {
      lenis = new Lenis({
        lerp: 0.1,
        smooth: true,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      });
      const scrollFn = (time) => {
        lenis.raf(time);
        requestAnimationFrame(scrollFn);
      };
      requestAnimationFrame(scrollFn);
    };

    initSmoothScrolling();

    return () => { };
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route element={<Homepage />} path="/" exact></Route>
        <Route element={<Aboutpage />} path="/about" exact></Route>
        <Route element={<Contactpage />} path="/contact" exact></Route>
        <Route element={<Infrasturecture />} path="/infrastructure" exact></Route>
        <Route exact element={<Servicespage />} path="/services"></Route>
        <Route exact element={<Subservice />} path="/service/:id"></Route>
        <Route exact element={<Blogspage />} path="/blog"></Route>
        <Route exact element={<Blogdetail />} path="/blog/:id"></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
