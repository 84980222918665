import React, { useEffect, useState } from 'react';
import { model } from './Blogsmodel';
import parse from "html-react-parser"
import { useParams } from 'react-router-dom';
import Rajeev from "../../Assets/Images/Doctor/Rajeev.png"
import { Helmet } from 'react-helmet';

const Blogdetail = () => {

    const { id } = useParams();
    const [blogdetail, setblogdetail] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
        setblogdetail(model.filter((val, i) => {
            return (val.path == id)
        }))

    }, []);

    return (
        <>
            {blogdetail.map((val, i) => (
                <Helmet>
                    <title>{val.blogTitle}</title>
                </Helmet>
            ))}

            <div className='w-4/6 m-auto mb-24 blog_detail flex'>
                {blogdetail.map((val, i) => (
                    <>
                        <div className='blog_detail_section'>
                            <div className="blog_title text-6xl mb-10">
                                <h1 className='font-bold'>{val.blogTitle}</h1>
                            </div>
                            <div className="description">
                                <div>{parse(val.description)}</div>
                            </div>
                        </div>

                        <div className="sidebar_container">
                            <div className="author_container">
                                <div className="author_image">
                                    <img src={val.author_image} alt="" />
                                </div>
                                <div className="author_name">
                                    <h3>{val.author}</h3>

                                    <p>{parse(val.author_description)}</p>
                                </div>
                            </div>
                        </div>
                    </>

                ))}
            </div>
        </>

    );
}

export default Blogdetail;
