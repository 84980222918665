import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Andrology from "../../Assets/Images/Andrology_drk.png";
import Uro_Can from "../../Assets/Images/CancerDark.png";
import Female from "../../Assets/Images/FemaleDark.png";
import Lapro from "../../Assets/Images/LaproscopicDark.png";
import Laser from "../../Assets/Images/LaserDark.png";
import Paediatric from "../../Assets/Images/PaediatricDark.png";
import Uro_Img from "../../Assets/Images/UrologicalConditions.png";
import Uro_Dyn from "../../Assets/Images/urodynamicsDark.png";





const Services = () => {


    // const [age, setAge] = React.useState('');

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    const [servicesArray, setservicesArray] = useState([
        {
            name: "Urology Cancer Surgeries",
            img: Uro_Can,
            subFields: ["Cancer of Kidney",
                "Bladder Cancer",
                "Testicular Cancer",
                "Cancer of the Penis",
                "Prostate cancer",
                "Adrenal Tumors",],
            path: "urology-cancer"
        },
        {
            name: "Laparoscopic Urological Surgery",
            img: Lapro,
            subFields: ["Laproscopic Urological Surgery"],
            path: "laparoscopic"
        },
        {
            name: "Laser Surgery",
            img: Laser,
            subFields: ["Laser Prostate Surgery",
                "Laser Kidney Or Urinary Stone Surgery",],
            path: "laser-surgery"
        },
        {
            name: "Andrology- Male Sexual Health",
            img: Andrology,
            subFields: ["Hypogonadism",
                "Varicocele",
                "Erectile Dysfunction",
                "Peyronie's Disease",
                "Loss of Libido [sexual desire] ",
                "Andropause",
                "Male Infertility",
                "Hydrocele",
                "Vasectomy",
                "Circumcision",
                "Rejoining the Vas to gain fertility again",
                "Illness of penis",
                "Testicular Pain Treatement",],
            path: "andrology-male"
        },
        {
            name: "Urodynamics & Uroflowmetry",
            img: Uro_Dyn,
            subFields: ["Neurological Bladder",
                "Bladder Outflow Obstruction In Young Male",
                "Urinary Incontinence In Males Or Females",
                "Urinary Retention",],
            path: "urodynamics"
        },
        {
            name: "Female Urology",
            img: Female,
            subFields: ["Recurrent Urinary Tract Infection",
                "Female Urinary Incontinence",
                "Cystocoele",
                "Pelvic Prolapse",
                "Nocturia or Increased Urinary Frequency",
                "Menopause",
                "Premarital counselling &amp; Sex related problems",
                "Vaginal Discharge Urinary complaints",
                "Infertility",],
            path: "female-urology"
        },
        {
            name: "Paediatric Urology",
            img: Paediatric,
            subFields: ["Problems of Penis and Phimosis",
                "Undescended Testies, Hydrocele",
                "Recurrent Urinary Tract Infections in children",
                "Reflux Of Urine From Bladder To Kidneys- VUR",
                "Cancers Of Kidney And Other Organs In Children",
                "Hypospadius",
                "Posterior Urethral [PU] Valves",],
            path: "paediatric-urology"
        },
        {
            name: "Urological Conditions",
            img: Uro_Img,
            subFields: ["Urinary stones - Lasers, Lithotripsy",
                "Prostate Hyperplasia -Lasers",
                "Urinary tract infection",
                "Stricture/Rupture of Urethra",
                "Kidney/Renal Transplantation",
                "Male or female Infertility",
                "Varicocele surgery",
                " Azoospermia or zero sperms count"],
            path: "urological-condition"
        },
    ]);

    return (
        <div className='section service_container relative flex items-center justify-center flex-col'>
            <div className="heading_container">
                <h1 className="services_heading">
                    Our Services
                </h1>
                <p>
                    Overview of all the treatments/surgeries the Hospital offers
                </p>
            </div>

            <div className="services_dropdown flex items-start justify-center flex-wrap w-4/5">
                {servicesArray.map((val, i) => (

                    <Accordion key={`service${i}`} className='accord_subservice m-4 w-4/12' expanded={expanded === val.name} onChange={handleChange(val.name)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel1a-content-${val.name}`}
                            id={`panel1a-header-${val.name}`}
                        >
                            <img className="w-20 h-20" src={val.img} alt="" />
                            <Typography>{val.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {val.subFields.map((item, i) => (
                                    <div key={i} className="link p-3">
                                        <Link to={`/service/${val.path}`}>{item}</Link>
                                    </div>
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>

                ))}
            </div>
        </div>
    );
}

export default Services;
