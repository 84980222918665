import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Email from "../../Assets/Images/Email.png";
import Location from "../../Assets/Images/Location.png";
import Phone from "../../Assets/Images/Phone.png";

const Contact = () => {




    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [formField, setformField] = useState(["Name", "Phone Number", "Appointment For", "Date", "Note"]);

    const [formState, setformState] = useState({});


    const onChange = (e) => {
        setformState({ ...formState, [e.target.name]: e.target.value })
    }



    const [startDate, setStartDate] = useState(new Date());

    const onSubmit = (e) => {
        e.preventDefault();
        setformState({ ...formState, "Date": startDate })



        const config = {
            SecureToken: "46a46de7-a575-4cc4-ae75-8bbc7e67fd0a",
            To: 'rhcpune@gmail.com',
            From: "Rraheeronaldo@gmail.com",
            Subject: "New Appointment",
            Body: JSON.stringify(formState)
        }

        if (window.Email) {
            window.Email.send(config).then(
                message => {

                    if (message == "OK") {
                        setformState({})
                        document.querySelectorAll('input').forEach((val, i) => {
                            val.value = ""
                        })
                        alert("You will receive a call on the number for the booking an appointment")
                    }
                }
            );
        }
    }


    return (
        <div className='section contact_section flex items-center justify-center flex-col'>
            <h1 className='text-7xl font-bold'>Contact</h1>

            <div className="contact_card flex relative">

                <div className="left flex items-start justify-evenly flex-col">
                    <div className="contact flex items-center justify-start mt-5">
                        <div className="image w-11">
                            <img className='' src={Phone} alt="" />
                        </div>
                        <div className="text">
                            <p><a href="tel:+917276894949">+91 72768 94949</a></p>
                        </div>
                    </div>
                    <div className="email flex items-center justify-start mt-5">
                        <div className="image w-11">
                            <img className='' src={Email} alt="" />
                        </div>
                        <div className="text">
                            <p>
                                <a href="mailto:rhcpune@gmail.com">rhcpune@gmail.com</a>
                            </p>
                        </div>
                    </div>
                    <a href="https://goo.gl/maps/AoNBFFfHj9AnSnYN6" target="_blank" rel="noopener noreferrer" className="address flex items-center justify-start mt-5">
                        <div className="image w-11">
                            <img className='' src={Location} alt="" />
                        </div>
                        <div className="text">
                            <p>304, Ashoka Centre, Bibwewadi Crossing Chowk,
                                Pune Satara Road, Pune, Maharashtra 411009</p>
                            <p> <b>LIFT IS AVAILABLE</b></p>
                        </div>

                    </a>
                    <div className="timing">
                        <h2 style={{ color: "#fff" }} className="text-2xl mb-6">Timing</h2>

                        <h3 style={{ color: "#fff" }} className="text-xl mb-1">Dr Rajeev Chaudhari</h3>
                        <div className="week flex items-start">
                            <p className='w-1/3 underline'>Monday - Friday:</p>
                            <p className='w-2/3'>
                                10:00am - 1:00pm
                                <br />
                                (Ruby Hall Clinic, Boat Club Road)
                                <br />
                                2pm to 6pm
                                <br />
                                <span>
                                    (Rahee Healthcare Hospital)
                                </span>
                            </p>
                        </div>
                        <div className="week flex items-start mt-4">
                            <p className='w-1/3 underline'>Saturday:</p>
                            <p className='w-2/3'>8:30 AM - 9:30 AM  <br />
                                <span>
                                    (Rahee Healthcare Hospital)
                                </span> </p>
                        </div>
                        <h3 style={{ color: "#fff" }} className="text-xl mb-1 mt-2">Dr Veena Chaudhari</h3>
                        <div className="week flex items-start">
                            <p className='w-1/3 underline'>Monday - Friday:</p>
                            <p className='w-2/3'>
                                11:30am - 4:30pm
                                <br />
                                <span>
                                    (Rahee Healthcare Hospital)
                                </span>
                            </p>
                        </div>
                        <div className="week flex items-start mt-4">
                            <p className='w-1/3 underline'>Saturday:</p>
                            <p className='w-2/3'>8:00 AM - 11:00 AM  <br />
                                <span>
                                    (Rahee Healthcare Hospital)
                                </span> </p>
                        </div>
                        <div className="week flex items-start mt-4">
                            <p className='w-1/3 underline'>Sunday:</p>
                            <p className='w-2/3'>Closed</p>
                        </div>

                    </div>
                </div>
                <div className="right ">
                    <div className="mt-9 flex flex-col items-center justify-around fields">
                        {formField.map((val, i) => {
                            if (val !== "Date") {
                                return <TextField className='w-4/5' key={i} name={val} id={val} label={val} variant="outlined" onChange={(e) => onChange(e)} />
                            } else {
                                return <DatePicker showIcon timeIntervals={30} className='Date_picker' showTimeSelect
                                    dateFormat="Pp" name='Date' selected={startDate} onChange={(date) => {
                                        setStartDate(date)
                                    }
                                    }
                                />
                            }
                        }
                        )}

                        <p className='font-bold'>
                            *We will call you for confirmation of appointments on the given Mobile number</p>

                        <button className="appointment_btn" onClick={(e) => onSubmit(e)}>
                            Send an enquiry
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Contact;
