import React from 'react';
import Blogs from '../Component/Blogs/Blogs';

const Blogspage = () => {
    return (
        <>
         <Blogs/>   
        </>
    );
}

export default Blogspage;
