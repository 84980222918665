import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='footer_container w-full flex align-top justify-around px-16'>
            {/* <div className="section_footer w-2/5">
                <h1 className="footer_heading">
                    About Us
                </h1>

                <div className="section_content w-3/4 mt-12">
                    <p>
                    Established in 2005, the team of experts at Rahee Healthcare urology Hospital in Pune consists of highly qualified and experienced urologists and medical consultants who offer comprehensive diagnostic and therapeutic services for a wide range of urological conditions.
                    </p>
                </div>
            </div> */}
            <div className="section_footer w-2/5">
                <h1 className="footer_heading">
                    Quick Links
                </h1>

                <div className="section_content mt-12">
                    <ul className="footer_menu">
                        <li className="footer_link">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="footer_link">
                            <Link to="/about">About</Link>
                        </li>
                        <li className="footer_link">
                            <Link to="/service">Services</Link>
                        </li>
                        <li className="footer_link">
                            <Link to="">Blog</Link>
                        </li>
                        <li className="footer_link">
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="section_footer w-2/5">
                <h1 className="footer_heading">
                    Contact Us
                </h1>

                <div className="section_content mt-12">
                    <p className="contact_number">
                        <b>Phone:- </b> <a href="tel:+917276894949">+91 72768 94949</a> 
                    </p>
                    <p className="contact_number mt-4">
                        <b>Address:- </b> 304, Ashoka Centre, Bibwewadi Crossing Chowk,
                        Pune Satara Road, Pune, Maharashtra 411009
                    </p>
                    <p className="contact_number mt-4">
                        <b>Email:- </b> <a href="mailto:rhcpune@gmail.com">rhcpune@gmail.com</a>
                    </p>
                </div>
            </div>
            {/* <div className="section_footer w-1/5">
                <h1 className="footer_heading">
                    Follow Us
                </h1>

                <div className="section_content mt-12">
                    <div className="social_container flex justify-between w-2/4">
                        <img src={Facebook} alt="" />
                        <img src={Linkedin} alt="" />
                        <img src={Twitter} alt="" />
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Footer;
