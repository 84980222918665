import UrologyCondition from "../../Assets/Images/UrologicalConditions_1.png";
import UrologyCancer from "../../Assets/Images/UrologicalSurgery.png";
import Andrology_Male from "../../Assets/Images/Andrology_1.png";
import Urodynamics from "../../Assets/Images/Female.png";
import FemaleUrology from "../../Assets/Images/Female.png";
import Paediatric from "../../Assets/Images/Pediatric.png";
import Laser from "../../Assets/Images/Laser.png";
import Laparoscopic from "../../Assets/Images/Laser.png"

export const servicesModel = [
    {
        name: "Urological Conditions",
        description: `Urological problems are illnesses and abnormalities that affect both men's and women's 
    reproductive systems and urinary tracts. Cancers and urinary tract infections are only two examples 
    of relatively common urological disorders that can range in severity from moderate to catastrophic. 
    Lifestyle choices including smoking, drinking, and obesity can also lead to urological issues.`,
        img: UrologyCondition,
        path: "urological-condition"
    },
    {
        name: "Urology Cancer Surgeries",
        description: `Surgery to treat cancer of the urinary tract, including the bladder, 
        kidneys, prostate, and ureters, is known as urology cancer surgery. Bladder, kidney, 
        prostate, and testicular cancers are the most prevalent varieties of urological cancers. 
        Various surgical techniques may be employed to remove cancer and any afflicted organs, 
        depending on the type and stage of the disease.`,
        img: UrologyCancer,
        path: "urology-cancer"
    },
    {
        name: "Andrology- Male Sexual Health",
        description: `The field of medicine known as andrology focuses on the health 
        of male genital organs. Men's reproductive health issues like male infertility 
        and sexual dysfunction are addressed, as well as their diagnosis, treatment, 
        and prevention. The growing awareness of problems with male sexual health has 
        made this relatively new branch of medicine more vital recently.`,
        img: Andrology_Male,
        path: "andrology-male"
    },
    {
        name: "Urodynamics & Uroflowmetry",
        description: `Urodynamics assesses the bladder's and the urine tract's efficiency. 
        It involves measuring bladder pressure and urine flow using specialist equipment. 
        Urodynamics is used to identify and treat bladder and lower urinary tract issues, 
        including urine incontinence, urinary retention, and other problems. Uroflowmetry 
        is a branch of urodynamics that measures the amount and rate of urine flow to determine 
        any obstructions in the urinary tract.`,
        img: Urodynamics,
        path: "urodynamics"
    },
    {
        name: "Female Urology",
        description: `Female urology is a branch of urology that specialises in disorders 
        of the female urinary system. Urinary incontinence, pelvic organ prolapse, 
        and interstitial cystitis are just a few of the disorders that can impact the 
        female urinary system which is addressed by female urology. Because of the high level of specialisation, 
        many female urologists hold dual board certifications in urology and female urology.`,
        img: FemaleUrology,
        path: "female-urology"
    },
    {
        name: "Paediatric Urology",
        description: `Children's urinary tract and genital issues are the focus areas that 
        paediatric urology specializes in. It addresses problems like bedwetting, urinary 
        tract infections, and congenital urinary tract abnormalities. In order to treat these 
        disorders, paediatric urologists—specialists in working with children—use a range of methods,
         including minimally invasive surgery. For kids with these types of problems, early identification 
         and treatment are important to reduce complications and improve outcomes.
        `,
        img: Paediatric,
        path: "paediatric-urology"
    },
    {
        name: "Laser Surgery",
        description: `Laser surgery in the field of urology is minimally invasive that 
        is used by expert urologists to remedy various kinds of urologic problems. 
        This type of surgery utilizes the heat energy of the laser to remove or 
        vaporize the affected tissues. A local anaesthetic is used during the procedure 
        and the chances of bleeding are also reduced in this type of surgery. 
        This kind of treatment is usually done on an outpatient basis. Bladder tumours, 
        prostate enlargement, and kidney stones are a few urologic diseases that can be cured by laser surgery. `,
        img: Laser,
        path: "laser-surgery"
    },
    {
        name: "Laparoscopic Urological Surgery",
        description: `Laparoscopic urological surgery, commonly referred to as minimally 
        invasive urologic surgery, is a type of surgery that involves making small 
        incisions in the abdomen to treat the male reproductive system and urinary 
        tract using a laparoscope and specialised tools. Laparoscopic surgery can provide
         a number of advantages over open surgery, including less discomfort, 
         fewer complications, and a quicker recovery. `,
        img: Laparoscopic,
        path: "laparoscopic"
    },
]