import axios from 'axios';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { model } from './Blogsmodel';



gsap.registerPlugin(ScrollTrigger);

const Blogsection = () => {


    const [responseData, setresponseData] = useState([]);




    useEffect(() => {
        let animationText = gsap.utils.toArray(".tile");

        if (animationText.length > 1) {
            animationText.forEach((section) => {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: section, // make .panel2 the trigger
                        start: "10% bottom",
                        toggleActions: "play none none reverse",
                    },
                })

                tl.fromTo(
                    section,
                    { y: 100, opacity: 0 },
                    {
                        y: 0,
                        opacity: 1,
                        duration: 0.5,
                    }
                )
            });
        }

    }, []);



    // const [colorArray, setcolorArray] = useState(['#6EDED1', '#6155ff', '#8bfcf9', '#33ffb1', '#d7ff99', '#19dbdb', '#8d69ff', '#bca4fc', '#bbf76d']);



    return (
        <div className='blog__section_container'>
            <div className="blog_header_container">
                <div className="heading_container">
                    <h1 className='heading'>
                        Blogs
                    </h1>
                </div>
            </div>

            <div className="blog_post_container">

                {model?.map((val, i) => (
                    <div key={i}>
                        <div className="tile">
                            <img className='w-full h-full' src={val.blogImg} alt="" />
                        </div>
                        <div className="title_container">
                            <div className="title">
                                <Link to={`/blog/${val.path}`}>{val.blogTitle}</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Blogsection;
