import React from 'react';
import { Link } from 'react-router-dom';
import { Model } from './Model';

const Infrasturecture = () => {
    return (
        <div className='section services_main_container'>
            <div className="page_heading_container text-center">
                <h1 className="title text-3xl font-bold">
                    State Of The Art Infrasturecture
                </h1>

                <p className="description text-2xl mt-5 w-11/12 m-auto">
                    At Rahee Healthcare, we only use certified equipment of an international standard to ensure safe and smooth procedures for all our patients.
                </p>
            </div>
            {Model.map((val, i) => (
                <div key={i} className={`services_container flex items-center justify-center w-11/12 m-auto p-8`} style={{ flexDirection: i % 2 == 0 ? "row" : "row-reverse" }}>
                    <div className="left w-1/2 p-4">
                        <div className="title">
                            <h1>{val.name}</h1>
                        </div>
                        <div className="description mb-12">
                            <p>{val.description}</p>
                        </div>
                    </div>
                    <div className="right w-1/2 p-4">
                        <div className="image">
                            <img className='m-auto' src={val.img} alt="" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Infrasturecture;
