import React from 'react';
import About from '../Component/Home/About';
import Achivement from '../Component/Home/Achivement';
import Banner from '../Component/Home/Banner';
import Services from '../Component/Home/Services';
import Testimonial from '../Component/Home/Testimonial';

const Homepage = () => {
    return (
        <>
            <Banner />
            <About />
            <Services />
            <Testimonial />
            <Achivement />
        </>
    );
}

export default Homepage;
