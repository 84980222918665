import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import Raj_img from "../../Assets/Images/Doctor/Rajeev.png";
import Vee_img from "../../Assets/Images/Doctor/Veena.png";
import Plus from "../../Assets/Images/Plus.png";

import Banner_one from "../../Assets/Images/Carousel/1.1.png";
import Banner_three from "../../Assets/Images/Carousel/2.3.png";
import Banner_four from "../../Assets/Images/Carousel/3.1.png";



const Banner = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const responsive = {
        0: {
            items: 1,
        },
        992: {
            items: 1,
        },
        1500: {
            items: 1,
        },
    }


    const [carouselImage, setcarouselImage] = useState([Banner_four, Banner_three, Banner_one]);


    return (

        <>
            
            <div className="banner_carousel">
                <div className="banner_text_container">
                    <div className="banner_heading">
                        <h1 className='heading'>
                            Rahee Healthcare - Urology Hospital in Pune
                        </h1>
                    </div>
                    <div className="banner_description_container">
                        <p>Rahee Healthcare is a Urology hospital in Pune offering personalised urological care since 2005 by Dr Rajeev Chaudhari & Dr Veena Chaudhari. Their combined experience of over two decades brings unmatched diagnostic and therapeutic services.</p>
                    </div>
                </div>
                <OwlCarousel className='owl-theme' autoplay={true} autoplayTimeout={5000} responsive={responsive} margin={20} loop>
                    {carouselImage.map((val, i) => (
                        <div key={`image${i}`} className="testimonial_card">
                            <img src={val} alt="" />
                        </div>
                    ))}
                </OwlCarousel>
            </div>

            <div className='section banner_container flex p-16 w-11/12 flex-row-reverse m-auto relative'>
                <div className="banner_text_container w-5/12 pl-20">
                    <h1 className="brand_name">
                        Meet Your Doctors
                    </h1>

                    <p className="text">
                        You are in safe hands as our experts
                        have
                    </p>

                    <p className="experience">
                        <span>
                            30+
                        </span>
                        <br />
                        years of experience in treating urology
                        patients
                    </p>

                    <p className="count">
                        <span>
                            40000+
                        </span>
                        <br />
                        patients satisfied surgical patients
                    </p>

                    <div className="btn_container">
                        <button className="appointment_btn">
                            <Link to="/contact">
                                Book Appointment
                            </Link>
                        </button>
                    </div>

                </div>
                <div className="banner_img_container w-7/12">
                    <div className="cards_container flex items-start justify-between">
                        <div className="card p-5 w-2/5">
                            <div className="image">
                                <img src={Raj_img} alt="Rahee Image" srcSet="" />
                            </div>
                            <div className="name">
                                <h1>
                                    Dr Rajeev Chaudhari
                                </h1>
                            </div>
                            <div className="description">
                                <p>
                                    Professor & HOD OF Urology
                                    <br />
                                    Senior Urologist and Andrologist at Ruby Hall Hospital
                                    <br />
                                    First to perform testicular denervation for chronic testicular pain in India
                                </p>
                            </div>

                            <Accordion key={`doctor_rajeev`} className='accord_subservice mt-8 w-full' expanded={expanded === "rajeev"} onChange={handleChange("rajeev")}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel1a-content-rajeev`}
                                    id={`panel1a-header-rajeev`}
                                >
                                    <p className='font-bold'>Appointment Timings </p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <p className="text-xl">
                                            <span className='underline'>Monday to Friday</span>
                                            <br />
                                            10am to 1pm
                                            <br />
                                            <span>(Ruby Hall Hospital, Boat Club Road)</span>
                                            <br />
                                            2pm to 6pm
                                            <br />
                                            <span>
                                                (Rahee Healthcare Hospital)
                                            </span>
                                            <br />
                                            <span className='underline'>Saturday</span>
                                            <br />
                                            8:30am to 9:30am
                                            <br />
                                            <span>
                                                (Rahee Healthcare Hospital)
                                            </span>
                                            <br />
                                            <br />
                                            To book an Appointment, please call
                                            <br />
                                            <a className='font-bold' href="tel:++91 72768 94949">+91 72768 94949</a>
                                        </p>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="card p-5 w-2/5">
                            <div className="image">
                                <img src={Vee_img} alt="Rahee Image" srcSet="" />
                            </div>
                            <div className="name">
                                <h1>
                                    Dr Veena Chaudhari
                                </h1>
                            </div>
                            <div className="description">
                                <p>
                                    Senior Uro-Gynaecologist
                                    <br />
                                    & Urodynamics Specialist
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </p>
                            </div>
                            <Accordion key={`doctor_veena`} className='accord_subservice mt-8 w-full' expanded={expanded === "veena"} onChange={handleChange("veena")}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel1a-content-veena`}
                                    id={`panel1a-header-veena`}
                                >
                                    <p className='font-bold'>Appointment Timing</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <p className="text-xl">
                                            <span className='underline'>Monday to Friday</span>
                                            <br />
                                            11:30am  to 4:30pm
                                            <br />
                                            <span>
                                                (Rahee Healthcare Hospital)
                                            </span>
                                            <br />
                                            <span className='underline'>Saturday</span>
                                            <br />
                                            8am to 11am
                                            <br />
                                            <span>
                                                (Rahee Healthcare Hospital)
                                            </span>
                                            <br />
                                            <br />
                                            To book an Appointment, please call
                                            <br />
                                            <a className='font-bold' href="tel:++91 72768 94949">+91 72768 94949</a>
                                        </p>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>

                </div>

                <div className="plus_img absolute -z-10">
                    <img src={Plus} alt="" />
                </div>
            </div>
        </>
    );
}

export default Banner;
