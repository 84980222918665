import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Subservices } from './Submodel';

const Subservice = () => {

    const { id } = useParams();
    const [subService, setsubService] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)

        setsubService(Subservices.filter((val, i) => {
            return (val.path == id)
        }))
    }, []);

    return (
        <div className='section subservice_container'>
            {subService.map((val, i) => (
                <div key={i} className='services_container flex items-center justify-center flex-col'>
                    <h1 className='subservice_title'>{val.title}</h1>
                    {val.content.map((data, i) => (
                        <div key={i} className={`subservices_container flex items-center justify-center w-11/12 m-auto p-8`} style={{ flexDirection: i % 2 == 0 ? "row" : "row-reverse" }}>
                            <div className="left w-1/2 p-4">
                                <div className="title">
                                    <h1>{data.name}</h1>
                                </div>
                                <div className="description mb-12">
                                    <p>{data.description}</p>
                                </div>

                                <Link className="appointment_btn" to={`/contact`}>
                                    Book An Appointment
                                </Link>
                            </div>
                            <div className="right w-1/2 p-4">
                                <div className="image">
                                    <img src={data.image} alt="" style={{ width: "35%", margin: "auto" }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Subservice;
