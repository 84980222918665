import React from 'react';
import { Link } from 'react-router-dom';
import { servicesModel } from './Model';

const Services = () => {
    return (
        <div className='section services_main_container'>
            {servicesModel.map((val, i) => (
                <div key={i} className={`services_container flex items-center justify-center w-11/12 m-auto p-8`} style={{ flexDirection: i % 2 == 0 ? "row" : "row-reverse" }}>
                    <div className="left w-1/2 p-4">
                        <div className="title">
                            <h1>{val.name}</h1>
                        </div>
                        <div className="description mb-12">
                            <p>{val.description}</p>
                        </div>

                        <Link className="appointment_btn" to={`/service/${val.path}`}>
                            Explore
                        </Link>
                    </div>
                    <div className="right w-1/2 p-4">
                        <div className="image">
                            <img src={val.img} alt="" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Services;
