import React from 'react';
import About_Banner from "../../Assets/Images/aboutus.png";

const Banner = () => {
    return (
        <div className='section about_banner_section section_container flex items-center relative'>
            <img className='mb-12 w-1/2 ml-20' src={About_Banner} alt="" />

            <div className="text_container w-1/2">
                <h1 className='text-7xl font-bold mb-8'>About Us</h1>
                <p className='text-xl w-3/4'>Rahee Healthcare was started in 2005 by Dr Rajeev Chaudhari and Dr Veena Chaudhari.
                    This Hospital was started with the aim of offering personalised & state-of-the-art
                    urological care for all economical classes. For more than a decade now, the Hospital
                    has been providing unmatched services to its patients by following the international
                    guidelines of the Urological Society. It is also one of the rare clinics to provide
                    pediatric urology in Pune.
                </p>
            </div>
        </div>
    );
}

export default Banner;
