import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../Assets/Images/Logo.png"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Phone from "../Assets/Images/Phone.png"
import Email from "../Assets/Images/Email.png"

const Navbar = () => {


    const menuRef = useRef();

    const menuShow = () => {
        menuRef.current.classList.add("menu--open")
    }

    const menuHide = () => {
        menuRef.current.classList.remove("menu--open")
    }

    return (
        <div className='w-full flex items-center justify-center flex-col'>
            <div className="top_bar w-screen">
                <div className="announcement_bar w-11/12 m-auto flex items-center justify-end">
                    <div className="email flex items-center">
                        <img src={Email} alt="" />
                        <a href="mailto:rhcpune@gmail.com">rhcpune@gmail.com</a>
                    </div>

                    <div className="mobile flex items-center">
                        <img src={Phone} alt="" />
                        <a href="tel:+917276894949">+91 72768 94949</a> 
                    </div>
                </div>
            </div>

            <div className='navbar_container flex items-start justify-between p-16 w-11/12 m-auto'>
                <div className="logo_container w-1/5">
                    <Link to="/">
                        <img className='w-1/4' src={Logo} alt="Rahee Healthcare" srcSet="" />
                    </Link>
                </div>
                <div className="menus w-2/4" ref={menuRef}>
                    <div className="closeIcon" onClick={(e) => menuHide(e)}>
                        <CloseIcon className='close' />
                    </div>
                    <div className="menu_container flex  items-center justify-between" >

                        <div className="menu_items">
                            <Link to="/" className='menu'>Home</Link>
                        </div>
                        <div className="menu_items">
                            <Link to="/about" className='menu'>About</Link>
                        </div>
                        <div className="menu_items">
                            <Link to="/services" className='menu'>Services</Link>
                        </div>
                        <div className="menu_items">
                            <Link to="/infrastructure" className='menu'>infrastructure</Link>
                        </div>

                        <div className="menu_items">
                            <Link to="/blog" className='menu'>Blog</Link>
                        </div>
                        <div className="menu_items">
                            <Link to="/contact" className='menu'>Contact</Link>
                        </div>
                    </div>
                </div>

                <div className="hamburger_menu" onClick={(e) => menuShow(e)}>
                    <MenuIcon className='menu_icon' />
                </div>
            </div>
        </div>

    );
}

export default Navbar;
