import Kidney_Cancer from "../../Assets/Images/Subservices/Cancer of Kidney 1.jpg"
import Kidney_Cancer_2 from "../../Assets/Images/Subservices/Cancer of Kidney 2.jpg"
import Bladder_Cancer from "../../Assets/Images/Subservices/Bladder Cancer.jpg"
import Adrenal from "../../Assets/Images/Subservices/Adrenal Tumor.jpg"
import Circumcision from "../../Assets/Images/Subservices/Circumcision.jpg"
import Turp from "../../Assets/Images/Subservices/HOLEP_ TURP_ THULEP .jpg"
import Hypospadius from "../../Assets/Images/Subservices/Hypospadius.jpg"
import Penis from "../../Assets/Images/Subservices/Illness of Penis 1 .jpg"
import Laser from "../../Assets/Images/Subservices/Laser.jpg"
import Litho from "../../Assets/Images/Subservices/Lithotripsy.jpg"
import PCNL from "../../Assets/Images/Subservices/PCNL & MINIPERC.jpg"
import Peyrone from '../../Assets/Images/Subservices/Peyrone Disease 1.jpg'
import Urodynamic from "../../Assets/Images/Subservices/Urodynamics & Uroflometry.jpg"
import Ureter from "../../Assets/Images/Subservices/Ureteroscopy.jpg"



export const Subservices = [
    {
        path: "urological-condition",
        title: "Urological Conditions",
        content: [
            {
                name: "Urinary stones - Lasers, Lithotripsy",
                description: "Urinary stones can be in the kidney , ureter ,bladder or urethra. They are likely to give significant pain, dysuria, blood in urine , nausea or vomiting or stoppage of urine flow. All the above category stones can be removed by simple endoscopic laser surgery – PCNL, MINIPERC, Ureteroscopy , RIRS etc. and a person can get back to work in a day or two. Open surgeries are not done for removing stones anymore. Endoscopic laser surgeries give far better clearance of stones.",
                image: Litho
            },
            {
                name: "Prostate Hyperplasia -Lasers",
                description: "Age, reduced flow, blood or pain in urine , sense of not completely passing urine ,etc. are typical complaints when prostate gets enlarged with advancing age. Prostate enlargement is the cause for complaints should be confirmed. Depending upon the degree of complaints and findings of investigations, prostate enlargement [ prostatomegaly ] can be treated by TURP, HOLEP, THULEP [ laser prostate surgery] or medication. The common false belief of urinery leak after surgery very rarely happens due to various technological advances. If needed, urodynamics investigation should be done if advised by the urologist before prostate surgery.",
                image: Turp
            },
            {
                name: "Urinary tract infection",
                description: "Any infection in the bladder, urethra, or kidneys, which are all parts of the urinary system, can have urinary tract infections. Most cases are easily treated with medication after urine culture and sensitivity report. Recurrent infections need to be taken seriously and investigated. Infections can cause long term problems especially if they reach kidneys.",
                image: ""
            },
            {
                name: "Stricture/Rupture of Urethra",
                description: "Stricture urethra is caused by trauma or infections or previous surgeries or catheterisation in urethra. that can cause urine to escape into surrounding tissues. Stricture of urethra causes reduced urine flow, infections, inability to completely pass urine, etc. Strictures are notorious to recur. Hence, endoscopic surgery [ OIU / VIU ] though the easier option should be tried only once or twice. Urethroplasty / buccal mucosa urethroplasty gives much better cure rate in the longer run.",
                image: ""
            },
            {
                name: "Kidney/Renal Transplantation",
                description: "Kidney transplant surgery is done from a living or deceased donor to a patient with a failing kidney. Donor nephrectomy is nowadays done only by laparoscopic surgery predominantly. A patient with transplanted kidney always needs to take medication and must be prepared for it.",
                image: ""
            },
            {
                name: "Male Infertility",
                description: `Infertility should be investigated only after 6 to 12 months of unprotected sex has been tries and success is not achieved. Infertility can be due to 
                1. no problems in either of the partners or 
                2. 50:50% possibility of problem in male or female. So first simple test a couple should do is a semen test of the male after 3 to 4 days of abstinence from sex. It is a simple, inexpensive, non invasive test.
                There are different causes of male infertility leaving patients unable to conceive a child. Infertility in males and females can be treated through medicines, surgery & /or assisted conception techniques.
                `,
                image: ""
            },
            {
                name: "Varicocele surgery",
                description: "A varicocele causes dilated veins in the scrotum and around the testis due to defective valve mechanism in the testicular veins. This results in problems in the working of testies, causing various complaints. A varicocelectomy [ varicocele surgery] is a type of surgery that is used to treat defective valves in the veins of a scrotum. The surgery treats varicocele to reduce testicular pain and increase male fertility. Open subinguinal micro-surgery gives the best cure rates.",
                image: ""
            },
            {
                name: " Azoospermia or zero sperms count",
                description: "Azoospermia means there are no sperms in the ejaculate due to blockage or non-production of sperm in the testis. The blockage of the pathway for sperms can be effectively treated by various surgeries. If the passage is opened and the sperms are seen in the semen, that gives a long term cure for male infertility.",
                image: ""
            },

        ]

    },
    {
        path: "urology-cancer",
        title: "Urology Cancer Surgeries",
        content: [
            {
                name: "Cancer of Kidney",
                description: "Kidney cancer is hard to detect in the early stage as it does not have clear signs. You may notice back pain, blood in the urine, and loss of appetite. Surgery remains the main treatment option in Kidney cancer. Almost all kidney cancers are now removed by key hole--laparoscopic or robotic surgery.",
                image: Kidney_Cancer
            },
            {
                name: "Prostate cancer",
                description: "If a person has blood in urine or pain while passing urine, reduced urine flow , urgency or other urinary complaints, then he must get checked by a Urologist. Cancer of prostate can be cured by robotic or laparoscopic surgery if diagnosed at early stage. Other treatment options are radiotherapy, hormonal therapy, watchful waiting ,etc. ",
                image: ""
            },
            {
                name: "Bladder Cancer",
                description: "This type of cancer generally affects older people. It can be cured completely if diagnosed and treated early on. Treatments include endoscopic surgery-laparoscopic or robotic included and / or chemotherapy. ",
                image: Bladder_Cancer
            },
            {
                name: "Testicular Cancer",
                description: "Cancer in the testicles can be identified through symptoms like lumps or a feeling of heaviness in the scrotum. It can be cured if diagnosed early & treated using surgery, radiation, and chemotherapy.",
                image: ""
            },
            {
                name: "Cancer of the Penis",
                description: "Penile cancer can be detected if a lump or thickening seen on glans or preputial /penile skin. Surgery is a the main treatment option for all stages of penile cancer. If diagnosed & treated early, the penis can be preserved completely. Lymph nodes should be removed by keyhole laparoscopic surgery to avoid delayed skin complications. This cancer can also be cured at early stage. ",
                image: ""
            },

            {
                name: "Adrenal Tumors",
                description: "This can occur at any age. Although rare, this type of cancer can occur in one or both of the adrenal glands which are located near the kidneys. It is removed with laparoscopic or robotic surgery.",
                image: Adrenal
            },
        ]

    },
    {
        path: "andrology-male",
        title: "Andrology- Male Sexual Health",
        content: [
            {
                name: "Hypogonadism",
                description: "Hypogonadism is a medical condition in which the body does not produce enough testosterone, the primary male sex hormone. This can lead to inadequate development of sex organs as well as problems can occur. A urologist must be consulted initially. An endocrinologist’s opinion may be required if advised.",
                image: Hypospadius
            },
            {
                name: "Varicocele",
                description: "A varicocele is a condition in which the veins in the testicles become enlarged. This can lead to reduced fertility and testicular pain.",
                image: ""
            }, {
                name: "Erectile Dysfunction",
                description: "Erectile dysfunction (ED) is the inability to achieve or maintain an erection sufficient for sexual activity & satisfaction. This can also be due to premature ejaculation. It is an easily treatable problem and a person should not be shy in getting a proper opinion from a qualified urologist. A first step is to avoid stress. It is worth noting that >10% of male population suffers from this problem. Many people feel they have ED and do not discuss the problem due to fear of social exposure. There is nothing to be afraid of I getting a qualified opinion. Please avoid going to quaks or non qualified persons.",
                image: ""
            }, {
                name: "Peyronie's Disease",
                description: "Peyronie's disease is a condition in which the penis is bent or curved due to the formation of scar tissue on the erectile tissue of the penis. A person can feel nodules or hard scar tissue on the penis.This may lead to inability to have sex and cause ED. There are good treatment options to get the penis straight and restore sexual life. It is not a cancer.",
                image: ""
            }, {
                name: "Loss of Libido [sexual desire] ",
                description: "Loss of libido, also known as decreased sexual desire, is a reduction in an individual's interest in sexual activity. This may due to reduction in the male hormone or other causes. ",
                image: ""
            }, {
                name: "Andropause",
                description: "Andropause, also known as male menopause, is a term used to describe the gradual decline in testosterone levels that can occur in ageing men. This can lead to many complaints such as loss of sexual desire, fatigue, tiredness and many other complaints. It can easily treated.",
                image: ""
            }, {
                name: "Male Infertility",
                description: "Male infertility is the inability of a man to cause a pregnancy. It can be treated through various methods such as medication or assisted conception.",
                image: ""
            }, {
                name: "Hydrocele",
                description: "A hydrocele is a fluid-filled sac that surrounds the testicles, which can cause pain or discomfort. This can be treated through surgery. A needle aspiration should not be done due to fear of introducing infection as well as recurrence of fluid after some time.",
                image: ""
            }, {
                name: "Vasectomy",
                description: "A vasectomy is a surgical procedure in which the vas deferens, the tubes that carry sperm from the testicles to the penis, are cut or blocked to prevent pregnancy. It is the male family planning method. This operation can be reversed if required due to unavoidable circumstances in future.",
                image: ""
            }, {
                name: "Circumcision",
                description: "Circumcision is a surgical procedure in which the foreskin of the penis is removed. This helps in avoiding repeated infections in future  & helps in diabetics with recurrent infections of glans or prepuce which in turn helps to get better control over their blood sugar. This can be done with simple open technique or with lasers or with staplers.",
                image: Circumcision
            },
            {
                name: "Illness of penis",
                description: "Illness of the penis refers to any health condition that affects the penis. These are infections of glans, cancer of penis, hypospadias or improper urethral opening, curvature of penis, peyronie’s disease or erection problems, phimosis or paraphimosis, etc.",
                image: Penis
            }, {
                name: "Testicular Pain Treatement",
                description: "Testicular pain treatment refers to the treatment of pain or discomfort in the testicles, which can have various causes. Chronic orchalgia means long standing pain in the testies in spite of taking treatment. This can be easily treated with surgery, called testicular denervation, with > 90% success, if done in properly indicated patients.",
                image: ""
            },
        ]

    },
    {
        path: "urodynamics",
        title: "Urodynamics & Uroflowmetry",
        content: [
            {
                name: "Neurological Bladder",
                description: "The neurological [ neurogenic ] bladder is a condition in which the bladder isn't functioning correctly due to a neurological disorder such as a spinal cord injury, multiple sclerosis, or a stroke. It needs to be differentiated and classified with the help of clinical findings and urodynamics.",
                image: Urodynamic
            },
            {
                name: "Bladder Outflow Obstruction",
                description: "Bladder outflow obstruction is a condition where the normal flow of urine out of the urethral opening is blocked, usually due to a physical obstruction such as an enlarged prostate, stricture urethra, etc. If BOO occurs in young males, it may need to be investigated in detail.",
                image: ""
            },
            {
                name: "Urinary Incontinence In Males Or Females",
                description: "Urinary incontinence means leakage of urine due to inadequate control. This can be due to bladder problems, incompetence of sphincter [ urethral closing mechanism ] or after surgery, etc. If it is moderate or severe then it affects quality of life of the person. It needs to be properly investigated, classified, and then treated. Most of the urinary  incontinence can be stopped to be able to leave normally in 80 to 85% of persons. Ealy opinion should be sought from a urologist.",
                image: ""
            },
            {
                name: "Urinary Retention",
                description: "Urinary retention is a condition in which an individual cannot completely empty their bladder when they try to urinate. This can be caused by an obstruction, bladder problem or nerve damage, among other factors. Urinary retention can be easily treated and a person can get back to normal life.",
                image: ""
            },
        ]

    },
    {
        path: "female-urology",
        title: "Female Urology",
        content: [
            {
                name: "Recurrent Urinary Tract Infection",
                description: "Recurrent urinary tract infections (UTIs) are a series of repeated UTI’s that happen over time. UTI’s are infections that occur in the urinary system, which includes the kidneys, bladder, ureters, and urethra. Treatments must be taken to avoid long term damage especially to kidneys.",
                image: ""
            },
            {
                name: "Female Urinary Incontinence",
                description: "Urinary incontinence means leakage of urine due to inadequate control. This can be due to bladder problems, incompetence of sphincter [ urethral closing mechanism ] or after surgery, etc. If it is moderate or severe then it affects quality of life of the person. It needs to be properly investigated, classified, and then treated. Most of the urinary  incontinence can be stopped to be able to leave normally in 80 to 85% of persons. Ealy opinion should be sought from a urologist.",
                image: ""
            },
            {
                name: "Cystocoele",
                description: "Cystocele is a condition in which the bladder descends and bulges into the vagina. This can happen when the muscles and tissues that support the bladder become weak. This can be easily corrected if symptomatic. Cystocele can cause urinary retention or recurrent infections and other difficulties.",
                image: ""
            },
            {
                name: "Pelvic Prolapse",
                description: "Pelvic prolapse is a condition in which the pelvic organs (such as the uterus, bladder, or rectum) bulge into or out of the vagina. It can happen due to weakened pelvic muscles and connective tissues. This can cause uriney complaints just like cystocele.",
                image: ""
            },
            {
                name: "Nocturia or Increased Urinary Frequency",
                description: "Nocturia or increased urinary frequency refers to waking up frequently during the night to urinate. This can be caused by a variety of factors such as an enlarged prostate, diabetes or certain medications, etc. It is one of the most frequent problems in senior citizens. This leads to lack of sleep and generalised weakness, fatigue, etc.",
                image: ""
            },
            {
                name: "Menopause",
                description: "Menopause is the time in a woman's life when her menstrual periods stop permanently. This typically occurs around the age of 50. There are lot of hormonal changes in the body, including the urinary passage and vagina. This may lead to urinary complaints, tiredness, irritability and so on.",
                image: ""
            },
            {
                name: "Premarital counselling & Sex related problems",
                description: "Premarital counselling is counselling given to couples before they get married. This can include discussing sexual health, relationship dynamics, and other important issues. This helps in smoother understanding of various factors in post marriage relationship.",
                image: ""
            },
            {
                name: "Vaginal Discharge & Urinary complaints",
                description: "Vaginal discharge and urinary complaints are symptoms that may occur due to many conditions such as infections or hormonal imbalances. If significant vaginal discharge is present, it needs to be properly treated before a person can expect relief from her urinary symptoms.",
                image: ""
            },
            {
                name: "Male Infertility",
                description: "If after a year of regular intercourse without the use of birth control if the female is unable to conceive it may be due to infertility. It can be caused by a variety of factors such as hormonal imbalances, blocked fallopian tubes or certain medical conditions.",
                image: ""
            },
        ]

    },
    {
        path: "paediatric-urology",
        title: "Paediatric Urology",
        content: [
            {
                name: "Problems of Penis and Phimosis",
                description: "Problems of the penis refers to any health condition that affects the penis. These are infections of glans, hypospadias or improper urethral opening, curvature of penis, epispadias, etc.",
                image: ""
            },
            {
                name: "Undescended Testies, Hydrocele",
                description: "Undescended testicles/cryptorchidism is a condition where one or both testicles fail to descend into the scrotum. This can be associated with Hydrocele, a fluid-filled sac that surrounds the testicles, which can cause pain or discomfort. Undescended testis or cryptorchidism must get treated and testis brought down to scrotum before 1 year of age. This helps in proper development of testis. At birth or within first 15 days, proper positioning of testis must be confirmed.",
                image: ""
            },
            {
                name: "Recurrent Urinary Tract Infections in children",
                description: "Recurrent urinary tract infections (UTIs) in children are a series of UTIs that occur over time. UTIs are infections that occur in the urinary system, which includes the ureters, urethra, kidneys, and bladder.",
                image: ""
            },
            {
                name: "Reflux Of Urine From Bladder To Kidneys- VUR",
                description: "Vesicoureteral reflux (VUR) is a condition in which urine flows back from the bladder into the ureters and sometimes the kidneys. This can cause infections and damage to the kidneys over time.",
                image: ""
            },
            {
                name: "Cancers Of Kidney And Other Organs In Children",
                description: "Cancers of the kidney and other organs in children is rare but can still occur. Causes can include genetic conditions or other factors like exposure to certain chemicals or radiation. This needs early treatment for getting cured and specialised treatment.",
                image: ""
            },
            {
                name: "Hypospadius",
                description: "The opening of the urethra being located on the underside of the penis, rather than at the tip is a congenital condition known as Hypospadias.",
                image: ""
            },
            {
                name: "Posterior Urethral [PU] Valves",
                description: "When the urethra is blocked by abnormal folds of tissue, it is a congenital abnormality known as Posterior urethral valves (PUV). This may lead urine to back up into the bladder and kidneys, causing long term damage and repeated infection.",
                image: ""
            },
            {
                name: "Stone Treatment In Chilldren",
                description: "Urinary stones in children can be in the kidney , ureter ,bladder or urethra. They are likely to give significant pain, dysuria, blood in urine , nausea or vomiting or stoppage of urine flow. All the above category stones can be removed by simple endoscopic laser surgery – PCNL, MINIPERC, Ureteroscopy , RIRS etc. and a can be free of pain in a day or two. Open surgeries are not done for removing stones anymore. Endoscopic laser surgeries give far better clearance of stones. Specialised surgical devices are required for children.",
                image: PCNL
            },
            {
                name: "Recurrent Urinary Tract Infections in Children",
                description: "Recurrent urinary tract infections (UTIs) in children are a series of UTIs that occur over time. UTIs are infections that occur in the urinary system, which includes the ureters, urethra, kidneys, and bladder. It is not good to have recurrent UTI’s in children and it must get treated to avoid long term damage to kidneys or urinary system.",
                image: ""
            },
        ]

    },
    {
        path: "laser-surgery",
        title: "Laser Surgery",
        content: [
            {
                name: "Laser Prostate Surgery",
                description: "Laser prostate surgery is a minimally invasive surgical procedure to treat an enlarged prostate. The surgery involves uses a laser to vaporize or remove excess prostate tissue. This can relieve symptoms such as frequent urination and difficulty starting or stopping a urinary stream.",
                image: Laser
            },
            {
                name: "Laser Kidney Or Urinary Stone Surgery",
                description: "A laser kidney surgery uses laser technology to break down the kidney stone particles to smaller pieces so that they can pass easily through the urinary tract. This method helps in lowering the risk of complications after the surgery as compared to traditional surgeries.",
                image: Laser
            },
        ]

    },
    {
        path: "laparoscopic",
        title: "Laparoscopic Urological Surgery",
        content: [
            {
                name: "Laproscopic Urological Surgery",
                description: "",
                image: ""
            }
        ]

    },
]