import React, { useEffect } from 'react';
import Banner from '../Component/About/Banner';
import Team from '../Component/About/Team';

const Aboutpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div>
            <Banner/>
            <Team/>
        </div>
    );
}

export default Aboutpage;
