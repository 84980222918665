import React from 'react';
import Raj_img from "../../Assets/Images/Doctor/Rajeev.png";
import Vee_img from "../../Assets/Images/Doctor/Veena.png";

const Team = () => {
    return (
        <div className='section team_section flex items-center justify-evenly flex-col'>
            <h1 className='text-7xl font-bold'>Meet The Doctors</h1>

            <div className="team_container flex items-start justify-evenly w-3/4">
                <div className="team w-1/2 p-9">
                    <div className="card">
                        <img src={Raj_img} alt="" />
                    </div>

                    <div className="text">
                        <p className='text-2xl font-bold'>Dr Rajeev Chaudhari</p>
                        <p className='text-xl'>Professor of Urology, Senior Consultant Urosurgeon and Andrologist, is attached to Rahee Urology Healthcare, Pune and is an Honorary Consultant at Ruby hall Hospital, Pune. Testicular denervation is a surgery that is only performed by Mr Rajeev in Pune.</p>
                    </div>
                </div>

                <div className="team w-1/2 p-9">
                    <div className="card">
                        <img src={Vee_img} alt="" />
                    </div>

                    <div className="text">
                        <p className='text-2xl font-bold'>Dr Veena Chaudhari</p>
                        <p className='text-xl'>Senior Consultant Uro-gynaecologist. With more than 20 years of experience in gynaecology and more than 10 years of experience in urology, she now practises as Uro-gynaecologist. </p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Team;
