import React from 'react';
import Backdrop from "../../Assets/Images/PlusBg.png"

const About = () => {
    return (
        <div className='section about_container relative flex items-center justify-center'>
            <div className="text_container flex items-center justify-between flex-col text-center">
                <h1 className='title'>
                    ABOUT RAHEE HEALTHCARE
                </h1>
                <p className='about_paragraph'>
                    <b>UROLOGY Speciality Hospital</b>
                    <br />
                    Established in 2005,the team of experts at Rahee
                    Healthcare Urology Hospital in Pune consists highly
                    qualified, experienced Senior urologists and
                    consultants. They offer comprehensive diagnostic ,
                    therapeutic and surgical services for wide range of
                    urological conditions. We have less than 1% of Surgical complications and none of our patient was shifted to ICU for problems in the last 18 years of inception Of Rahee healthcare.
                </p>
            </div>

            <div className="backdrop absolute right-0 top-0">
                <img src={Backdrop} alt="" />
            </div>
        </div>
    );
}

export default About;
