import React,{useEffect} from 'react';
import Services from '../Component/Services.js/Services';

const Servicespage = () => {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, []);

    return (
        <>
            <Services />
        </>
    );
}

export default Servicespage;
